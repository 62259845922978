import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy_wrapper">

      <h1 style={{marginBottom: '15px'}}>
        INFORMATION ABOUT THE PROCESSING
        OF PERSONAL DATA
      </h1>
      <div className="privacy_content">
        <p>
          In relation to the Regulation of the European Parliament and of the Council (EU) 2016/679 of 27 April
          2016 on the protection of natural persons with regard to the processing of personal data and on the free
          movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation) and Act
          No. 18/2018 Coll. on personal data protection, we comply with the information obligation relating to the
          processing of your personal data.
        </p>

        <p>
          Kindly read the below information on how we process your personal data. Who is the controller of your
          personal data?
        </p>

        <p>
          The controller of your personal data is MINDWORX, ltd. with its registered office at
          Kresánkova 3582/7C Bratislava 841 05, Company ID: 50 101 749, registered in the Business
          Register of the District Court of Bratislava I, Division: Sro, Insert No.: 109326/B.
        </p>

        <p>
          For what purposes do we process your personal data and what is the legal basis for such processing?
          Processing according to a contract and pre-contract relations:
        </p>

        <p>
          In relation to the supply of our services and products, we are entitled to process your personal data and
          the data of your employees on the basis of a contractual relation without your consent.
        </p>

        <p>
          Personal data obtained from you by filling in the contact form serves the purpose of pre-contract
          relations (e.g. a request for a price offer, request for more detailed specification of the services provided,
          product information) and we are also entitled to process it without your consent.
        </p>
      </div>
      <p className="privacy_miniTitle">
        Processing on the basis of consent
      </p>
      <div className="privacy_content">
        We may process your personal data only with your voluntary consent for the purpose of sending the free
        company newsletter, by means of which we can inform you about our company’s news. You are entitled
        to withdraw your consent at any time by clicking on the withdrawal link directly in the sent e-mail in the
        newsletter (disclaimer) or by sending your request to the e-mail:
        <a href="mailto:info@mindworxacademy.com">info@mindworxacademy.com</a>
        .
      </div>

      <p className="privacy_miniTitle">
        How long will we retain your personal data?
      </p>
      <div className="privacy_content">
        <p>
          We will retain your personal data during the term of the contract and for the legally determined period
          for its archiving, the period needed to prove legal claims, or period the necessary to achieve a given
          purpose.
        </p>

        <p>
          Personal data obtained by filling in a contact form for the purpose of pre-contract relations is processed
          exclusively for a period of 1 year, and if the contact is not concluded, it will be deleted from the system.
          We are entitled to process the personal data obtained with your consent (for receiving the newsletter) for
          this purpose until the consent is withdrawn.{' '}
        </p>
      </div>
      <p className="privacy_miniTitle">
        What are your rights in relation to your personal data processing?
      </p>
      <ul className="privacy_list">
        <li>
          Right of access to personal data – you have the right to request that we confirm that we process
          your personal data.
        </li>

        <li>
          Right to the correction of personal data – you have the right to the correction of your personal
          data, if it is inaccurate or incomplete.
        </li>

        <li>Right to deletion (to be forgotten). </li>
      </ul>

      <p className="privacy_miniTitle">
        We are obliged to delete your personal data without any undue delay, if:
      </p>

      <ul className="privacy_list">
        <li>
          personal data is no longer needed for the purposes for which it was obtained or processed in other
          way,
        </li>
        <li>
          you withdraw your consent according to which the processing is performed,
        </li>
        <li>there is no legal basis for your personal data processing,</li>
        <li>
          you object to the processing and no justified reasons for processing prevail,
        </li>
        <li>
          you object to the processing and the personal data was processed illegally,
        </li>
        <li>personal data must be deleted to comply with legal obligations,</li>
        <li>
          personal data was obtained in relation to the offer of information society services.
        </li>
      </ul>

      <p className="privacy_miniTitle">
        The controller does not exercise the request for deletion if processing is required:
      </p>

      <ul className="privacy_list">
        <li>to exercise the right to freedom of speech and information,</li>
        <li>to comply with a legal obligation,</li>
        <li>to perform a duty performed in the public interest,</li>
        <li>due to public interest in the area of public health,</li>
        <li>
          for the needs of archiving in the public interest, for scientific or historical research or
        </li>
        <li>
          statistical purposes, to prove, exercise, or defend legal claims.{' '}
        </li>
      </ul>

      <p className="privacy_miniTitle">
        Right to restrict the personal data processing:
      </p>
      {/* <div className="privacy_content"> */}
      <p>
        You have the right to request that we restrict the processing of your personal data in one of the following
        instances:
      </p>
      {/* </div> */}

      <ul className="privacy_list">
        <li>
          you object the correctness of the personal data, we will restrict processing it until we verify its
          correctness,
        </li>

        <li>
          if we process your personal data illegally and you object against the deletion of personal data
          and request that its use be restricted instead,
        </li>

        <li>
          we no longer need your personal data, but you need it to prove, exercise, or defend legal claims,
        </li>

        <li>
          you object to the processing of your personal data, we will restrict its processing until
          verification whether our justified interests prevail over your reasons.
        </li>

      </ul>

      <p>
        If processing is restricted, such personal data is processed except for retention:
      </p>

      <ul className="privacy_list">
        <li>only with your consent,</li>
        <li>to prove, exercise, or defend legal claims,</li>
        <li>
          for the protection of the rights of another natural or legal person,
        </li>
        <li>due to important public interest. </li>
      </ul>

      <p className="privacy_miniTitle">
        Right to personal data transfer
      </p>
      <div className="privacy_content">
        You have the right to obtain the personal data you provided to us and you have the right to transfer the
        data to another operator (e.g. a certain service provider change). We are obliged to provide you with your
        data based on your request in a structured, typically used, and machine-readable format (e.g. XML or
        CSV).
      </div>

      <p className="privacy_miniTitle">
        Who can you contact in relation to your personal data processing?{' '}
      </p>
      <div className="privacy_content">
        Should you have any questions or suggestions relating to the processing of your personal data or should
        you wish to exercise any of your rights, please contact us at: info@mindworxacademy.com. You also have
        the right to file a complaint to the Office for Personal Data Protection if you believe that your rights have
        been violated.
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
